import axios from 'axios'

import Auth from '../Auth'
import Env from '../Env'

export const auth = new Auth()

export const adminApi = axios.create({
  baseURL: Env.ADMIN_API,
  timeout: 15000,
  headers: {
    Authorization: 'Bearer ' + auth.getAccessToken()
  }
})
