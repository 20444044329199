import { Loading } from '@skedulo/sked-ui';
import React from 'react';
import { Redirect, Route, RouteComponentProps, RouteProps, Switch } from 'react-router'
import { BrowserRouter } from 'react-router-dom'
import { SemanticToastContainer } from 'react-semantic-toasts'
import { auth } from './actions/Endpoints'
import './App.css';
import { NominationForm } from './views/NominationForm'
import { Login } from './views/Login'

const handleAuthentication = (nextState: RouteComponentProps) => {
  if (/access_token|id_token|error/.test(nextState.location.hash)) {
    auth.handleAuthentication()
  }
}

const AuthRoute = ({ component, ...args }: RouteProps & { component: RouteProps['component'] }) => {
  const Component = component as React.ComponentClass
  const render = (props: RouteComponentProps) =>
    auth.isAuthenticated() ? <Component {...props} /> : <Redirect to={{ pathname: '/login' }} />
  // eslint-disable-next-line react/jsx-no-bind
  return <Route {...args} render={render} />
}

const handleCallback = (props: RouteComponentProps) => {
  handleAuthentication(props)
  return <Loading align="center" />
}

export const App = () => {
  return (
    <BrowserRouter>
      <div id="main">
        <SemanticToastContainer className="alert-toast" position="top-right" />
        <Switch>
          <AuthRoute exact={true} path="/" component={NominationForm} />
          <Route exact={true} path="/login" component={Login} />
          <Route path="/callback" render={handleCallback} />

        </Switch>
      </div>
    </BrowserRouter>
  )
}
