import { adminApi } from './Endpoints'

export interface User {
  name: string,
  email: string
}

export interface NominationBody {
  nominee: string
  story: string
  companyValues: string[]
}

export const getUserList = async () => {
  const response = await adminApi.get<User[]>('/skedgend/users')
  return response.data
}

export const getCompanyValues = async () => {
  const response = await adminApi.get<string[]>('/skedgend/company-values')
  return response.data
}

export const submitNomination = async (body: NominationBody) => {
  return adminApi.post('/skedgend/nominate', body)
}
