import * as React from 'react'
import { Redirect } from 'react-router'
import { Grid, Header, Image, Button } from 'semantic-ui-react'
import { auth } from '../actions/Endpoints'
import Env from '../Env'
import loginLogo from '../images/login-logo.png'

export class Login extends React.Component {
  login() {
    auth.login()
  }

  loginTesting() {
    auth.loginTesting()
  }

  render() {
    if (auth.isAuthenticated()) {
      return <Redirect to="/console" />
    }
    return (
      <div id="login-form">
        <Grid textAlign="center" verticalAlign="middle">
          <Grid.Column style={{ maxWidth: 450 }}>
            <Image src={loginLogo} verticalAlign="middle" />
            <Header as="h1" color="grey" textAlign="center">
              Skedgend
            </Header>
            <Button onClick={this.login} size="massive" color="blue" fluid={true} className="sked-blue">
              Login
            </Button>
            {Env.AUTH0_CALLBACK === 'http://localhost:3000/callback' ? (
              <Button
                onClick={this.loginTesting}
                size="massive"
                color="blue"
                fluid={true}
                className="sked-blue"
                id="login-testing"
              >
                Login Testing
              </Button>
            ) : null}
          </Grid.Column>
        </Grid>
      </div>
    )
  }
}
