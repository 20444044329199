interface IConfig {
  AUTH0_CLIENT_ID: string
  AUTH0_CLIENT_DOMAIN: string
  AUTH0_AUDIENCE: string
  AUTH0_CALLBACK: string
  ADMIN_API: string
}

const Env: IConfig = (window as any).dynamic_env as IConfig

export default Env
