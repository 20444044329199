import * as Auth0 from 'auth0-js'
import Env from './Env'

export default class Auth {
  userProfile?: Auth0.Auth0UserProfile

  auth0 = new Auth0.WebAuth({
    domain: Env.AUTH0_CLIENT_DOMAIN,
    clientID: Env.AUTH0_CLIENT_ID,
    redirectUri: Env.AUTH0_CALLBACK,
    audience: Env.AUTH0_AUDIENCE,
    responseType: 'token id_token',
    scope: 'openid groups permissions roles profile'
  })

  login = (): void => {
    this.auth0.authorize({ connection: 'skedulo-com' })
  }

  loginTesting = (): void => {
    this.auth0.authorize({ connection: 'Username-Password-Authentication' })
  }

  getAccessToken(): string | undefined {
    const accessToken = localStorage.getItem('access_token')
    if (!accessToken) {
      return undefined
    }
    return accessToken
  }

  getProfile = (): Promise<Auth0.Auth0UserProfile> => {
    const accessToken = this.getAccessToken()
    return new Promise((resolve, reject) => {
      if (!accessToken) {
        reject(new Error('No access token present'))
      } else {
        if (this.userProfile) {
          resolve(this.userProfile)
        } else {
          this.auth0.client.userInfo(accessToken, (err, profile) => {
            if (err) {
              reject(err)
            }
            this.userProfile = profile
            resolve(this.userProfile)
          })
        }
      }
    })
  }

  handleAuthentication = (): void => {
    this.auth0.parseHash((error, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult)
      } else if (error) {
        window.location.href = '/login'
        throw error
      }
    })
  }

  setSession(authResult: Auth0.Auth0DecodedHash) {
    // Set the time that the access token will expire at
    let expiresAt = ''
    if (authResult.expiresIn !== undefined) {
      expiresAt = JSON.stringify(authResult.expiresIn * 1000 + new Date().getTime())
    }
    if (authResult.accessToken !== undefined) {
      localStorage.setItem('access_token', authResult.accessToken)
    }
    if (authResult.idToken !== undefined) {
      localStorage.setItem('id_token', authResult.idToken)
    }
    localStorage.setItem('expires_at', expiresAt)
    window.location.href = '/'
  }

  logout = (): void => {
    // Clear access token and ID token from local storage
    localStorage.removeItem('access_token')
    localStorage.removeItem('id_token')
    localStorage.removeItem('expires_at')
    window.location.href = '/login'
  }

  isAuthenticated = (): boolean => {
    // Check whether the current time is past the
    // access token's expiry time
    let expiresAt = new Date().getTime()
    const expiresAtString: string | null = localStorage.getItem('expires_at')
    if (expiresAtString !== null) {
      expiresAt = JSON.parse(expiresAtString)
    }
    return new Date().getTime() < expiresAt
  }
}
