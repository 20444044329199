import * as React from 'react'
import { Form, Header, Loader, Segment } from 'semantic-ui-react'
import { TextAreaProps } from 'semantic-ui-react/dist/commonjs/addons/TextArea/TextArea'
import { DropdownProps } from 'semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown'
import { getCompanyValues, getUserList, submitNomination, User } from '../actions/Nomination'
import { createErrorToast, createSuccessToast } from './errorComponents/Error'

interface NominationFormState {
  usersList: User[]
  companyValues: { [x: string]: boolean }
  loading: boolean
  nomineeEmail: string
  nomineeStory: string
  submitting: boolean
}

export class NominationForm extends React.PureComponent<object, NominationFormState> {

  constructor(props: object) {
    super(props)
    this.state = {
      usersList: [],
      companyValues: { },
      loading: false,
      nomineeEmail: '',
      nomineeStory: '',
      submitting: false
    }
  }

  async componentDidMount() {
    this.setState({ loading: true })
    await Promise.all([this.getUsers(), this.getCompanyValues()])
    this.setState({ loading: false })
  }

  async getUsers() {
    try {
      const usersList = await getUserList()
      this.setState({ usersList })
    } catch (error) {
      createErrorToast(error)
    }
  }

  async getCompanyValues() {
    try {
      const companyValuesArray = await getCompanyValues()
      const companyValues: { [x: string]: boolean } = { }
      companyValuesArray.forEach(
        (name) => companyValues[name] = false
      )
      this.setState({ companyValues })
    } catch (error) {
      createErrorToast(error)
    }
  }

  mapUsersToOption() {
    return this.state.usersList.map(user => ({ text: user.name, value: user.email }))
  }

  toggleValue = (value: string) => () => {
    console.log(this.state.companyValues)
    this.setState(prevState => ({ companyValues: { ...prevState.companyValues, [value]: !prevState.companyValues[value] } }))
  }

  onChange = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps | TextAreaProps) => {
    if (Object.keys(this.state).includes(data.name)) {
      this.setState(prevState => ({ ...prevState, [data.name]: data.value as string }))
    }
  }

  validateForm = () => {
    return this.state.usersList.map(user => user.email).includes(this.state.nomineeEmail) &&
      this.state.nomineeStory && this.state.nomineeStory !== ''
  }

  submitForm = async () => {
    if (this.validateForm()) {
      const companyValues: string[] = []
      Object.keys(this.state.companyValues).forEach( value => {
        if (this.state.companyValues[value]) {
          companyValues.push(value)
        }
      })
      try {
        this.setState({ submitting: true })
        await submitNomination({
          nominee: this.state.nomineeEmail,
          story: this.state.nomineeStory,
          companyValues: companyValues
        })
        createSuccessToast('Successfully Submitted Nomination')
      } catch (error) {
        createErrorToast(error)
      } finally {
        this.setState({ submitting: false })
      }
    }
  }

  render() {
    return (
      <body style={{ maxWidth: '800px', margin: 'auto' }}>
        <Segment color="blue" style={{ marginTop: '1rem' }}>
          <Header>Automated Skedgend MK1</Header>
          <p>
            Welcome to the v1 of the new skedgend system.
            As the weeks go by expect new features and a new look to show up here,
            but for now you can nominate your team members below.
          </p>
        </Segment>
        <Segment color="blue" style={{ marginTop: '1rem'}}>
          <Header>Nominate a Skedgend</Header>
          <Form
            onSubmit={ this.submitForm }
          >
            <Form.Select
              required
              label='Skedgend Nominee Name'
              options={ this.mapUsersToOption() }
              placeholder='Name'
              search
              loading={ this.state.loading }
              value={ this.state.nomineeEmail }
              onChange={ this.onChange}
              name="nomineeEmail"
            />
            <Form.TextArea
              onChange={ this.onChange }
              value={ this.state.nomineeStory }
              required
              label='Stories are important! Tell us why this Skedulo employee deserves to be the Skedgend of the month:'
              placeholder='Tell us why this person is a skedgend..'
              name="nomineeStory"
            />
            <Form.Group grouped>
              <label>Does this story relate to our values? If so, select from the values below:</label>
              {
                this.state.loading ?
                  <div>
                    <Loader active inline />
                  </div>
                :
                  Object.keys(this.state.companyValues).map(value =>
                    <Form.Checkbox
                      key={ value }
                      label={ value }
                      checked={ this.state.companyValues[value] }
                      onClick={ this.toggleValue(value) }
                    />
                  )
              }
            </Form.Group>
            <Form.Button
              content="Submit"
              type="submit"
              loading={ this.state.submitting }
            />
          </Form>
        </Segment>
      </body>
    )
  }
}
